<template>
  <PrimaryTemplate
    :step="step"
    :buttonFunction="submit"
    :needBackButton="true"
    :backFunction="back"
    :mainTitle="'Your techstack'"
    :loading="loading"
  >
    <TextInput
      name="os"
      labelText="Operating systems"
      placeholder="Example: Linux, Windows, Mac OS, iOS, Android etc."
      :shouldValidate="false"
      v-model="os"
    />
    <TextInput
      name="database"
      labelText="Database"
      placeholder="Example: PostgreSQL, MongoDB, MySQL, MariaDB, BD2 etc."
      :shouldValidate="false"
      v-model="database"
    />
    <TextInput
      name="programmingLanguage"
      labelText="Programming languages"
      placeholder="Example: Java, Python, Ruby, JavaScript, PHP etc."
      :shouldValidate="false"
      v-model="programmingLanguage"
    />
    <TextInput
      name="otherTech"
      labelText="Other technologies"
      placeholder="Example: REST, Memcached, Selenium, SQL etc."
      :shouldValidate="false"
      v-model="otherTech"
    />
    <TextInput
      name="backendFramework"
      labelText="Backend frameworks"
      placeholder="Example: Spring, Ruby on Rails, Laravel, Flask, Express etc."
      :shouldValidate="false"
      v-model="backendFramework"
    />
    <TextInput
      name="frontEndFramework"
      labelText="Frontend frameworks"
      placeholder="Example: React, Angular, Vue, Svelte etc."
      :shouldValidate="false"
      v-model="frontEndFramework"
    />
    <TextInput
      name="mobileFramework"
      labelText="Mobile frameworks"
      placeholder="Example: React Native, Ionic, PhoneGap, Cordova etc."
      :shouldValidate="false"
      v-model="mobileFramework"
    />
    <div v-if="errorMessage" class="error-message red">
      {{ errorMessage }}
    </div>
  </PrimaryTemplate>
</template>
<script>
import PrimaryTemplate from "@/layouts/PrimaryTemplate.vue";
import TextInput from "@/components/utility/TextInput.vue";
import { update } from "../api/formdata";

export default {
  name: "TechnoStack",
  data() {
    return {
      step: "TechnoStack",
      errorMessage: null,
      loading: false,
    };
  },
  computed: {
    os: {
      get() {
        return this.$store.getters.getOS;
      },
      set(value) {
        this.$store.dispatch("setFifthStepField", {
          field: "os",
          value: value,
        });
      },
    },
    database: {
      get() {
        return this.$store.getters.getDatabase;
      },
      set(value) {
        this.$store.dispatch("setFifthStepField", {
          field: "database",
          value: value,
        });
      },
    },

    programmingLanguage: {
      get() {
        return this.$store.getters.getProgrammingLanguage;
      },
      set(value) {
        this.$store.dispatch("setFifthStepField", {
          field: "programmingLanguage",
          value: value,
        });
      },
    },

    otherTech: {
      get() {
        return this.$store.getters.getOtherTech;
      },
      set(value) {
        this.$store.dispatch("setFifthStepField", {
          field: "otherTech",
          value: value,
        });
      },
    },

    backendFramework: {
      get() {
        return this.$store.getters.getBackendFramework;
      },
      set(value) {
        this.$store.dispatch("setFifthStepField", {
          field: "backendFramework",
          value: value,
        });
      },
    },

    frontEndFramework: {
      get() {
        return this.$store.getters.getFrontEndFramework;
      },
      set(value) {
        this.$store.dispatch("setFifthStepField", {
          field: "frontEndFramework",
          value: value,
        });
      },
    },

    mobileFramework: {
      get() {
        return this.$store.getters.getMobileFramework;
      },
      set(value) {
        this.$store.dispatch("setFifthStepField", {
          field: "mobileFramework",
          value: value,
        });
      },
    },
  },
  methods: {
    async submit() {
      let modifyLoading = this.modifyLoading;
      let setError = this.setError;
      let router = this.$router;

      modifyLoading(true);
      setError(null);

      let thisData = {
        os: this.os,
        database: this.database,
        programmingLanguage: this.programmingLanguage,
        otherTech: this.otherTech,
        backendFramework: this.backendFramework,
        frontEndFramework: this.frontEndFramework,
        mobileFramework: this.mobileFramework,
      };
      let currentData = JSON.parse(localStorage.getItem("data"));
      currentData.data.push(thisData);

      await update(currentData).then((response) => {
        if (response.status >= 200 && response.status <= 300) {
          modifyLoading(false);

          let thisDataStringified = JSON.stringify(currentData);
          localStorage.setItem("data", thisDataStringified);
          router.push({ path: "/extra-information" });
        } else {
          modifyLoading(false);
          if (response.status == 400) {
            setError(
              "Validation error! Please check the accuracy of the entered data!"
            );
          } else {
            setError("Form submission error! Please try again later!");
          }
          console.log("Error: ");
          console.log(response.status);
        }
      });
    },
    back() {
      let currentData = JSON.parse(localStorage.getItem("data"));
      currentData.data.pop();
      let thisDataStringified = JSON.stringify(currentData);
      localStorage.setItem("data", thisDataStringified);

      this.$router.push({ path: "/experience" });
    },
    modifyLoading(loading) {
      this.loading = loading;
    },
    setError(message) {
      this.errorMessage = message;
    },
  },

  components: {
    PrimaryTemplate,
    TextInput,
  },
};
</script>

<style lang="scss" scoped>
.text-input-wrapper {
  margin-bottom: 25px;
}
.red {
  color: red;
}
:disabled {
  opacity: 0.4;
}
</style>
